export const RouteTable = {
  CollectibleListings: "collectibleListings?dropType=SINGLE_DROP_LISTING",
  Transactions: "transactions",
  Collection: "collection",
  Users: "users",
  Wallets: "wallets",
  Home: "home",
  PriviligedUsers: "app/internal/privilegedUsers",
  SmartContractTypes: "app/internal/smartContractTypes",
  collectionSetRoute: (setId) => `collection/sets/${setId}`,
  homeRoute: (org) => `/app/org/${org}/home`,
}
