import { useToast } from "@chakra-ui/react"
import { useGrowthBook } from "@growthbook/growthbook-react"
import { AdminUser, App, Organization } from "@niftory/sdk/react"
import { OrganizationRole, Role } from "@prisma/client"
import { useRouter } from "next/router"
import { Session } from "next-auth"
import { createContext, useEffect, useMemo, useState } from "react"
import TosModal from "src/components/shell/Legal/Terms/termsModal"
import { RouteTable } from "src/constants/RouteTable"
import { useAdminUserSession } from "src/hooks/core/useAdminUserSession"

import { getUserRole } from "../utils/api-utils"
import { UserWithOrgs } from "../utils/prisma-types"

interface IAppContext {
  session: Session
  currentApp: App
  currentOrganizationApps: App[]
  currentOrganization: Organization
  currentOrganizationRole: OrganizationRole
  user: AdminUser
  loading: boolean
  setCurrentApp: (app: App) => void
  setCurrentOrganization: (organization: Organization) => void
  setCurrentOrganizationAndApp: (organization: Organization) => void
}

export const AppContext = createContext<IAppContext>(null)

export function mapOrganizationToRole(
  organization: Organization,
  userId: string
): Organization & { userRole: OrganizationRole } {
  const userRole = organization?.members?.find((item) => item.id === userId)?.organizationRole
  return {
    ...organization,
    userRole,
  }
}
export function hasAccess(requiredRole: Role = Role.CREATOR, currentRole: Role): boolean {
  const requiredIndex = Object.values(Role).indexOf(requiredRole)
  const currentIndex = Object.values(Role).indexOf(currentRole)

  return currentIndex >= requiredIndex
}

export function isRole(requiredRole: Role = "CREATOR", currentRole: Role): boolean {
  return requiredRole === currentRole ? true : false
}

export function isNiftoryAdmin(user: AdminUser): boolean {
  if (user && getUserRole(user) === Role.ADMIN && user.apps) {
    for (const org of user.apps) {
      if (org && org?.id === "Niftory") {
        return true
      }
    }
  }
  return false
}

export function isOrgAdmin(user: UserWithOrgs): boolean {
  return !isNiftoryAdmin(user) && getUserRole(user) === Role.ADMIN
}

export function AppProvider({ children, role }) {
  const router = useRouter()

  const { session, user, loading, refreshSession } = useAdminUserSession()
  const [currentApp, _setCurrentApp] = useState<App>()
  const [currentOrganization, setCurrentOrganization] = useState<Organization>()
  const growthBook = useGrowthBook()
  const [showModal, setShowModal] = useState(false)

  // Store appId in localStorage
  const setCurrentApp = (app: App) => {
    console.log(app)
    localStorage.setItem("currentApp", app.id)
    _setCurrentApp(app)
  }

  // When org loads/changes set it as a growthbook attribut
  useEffect(() => {
    const attributes = growthBook?.getAttributes()
    growthBook?.setAttributes({
      ...attributes,
      orgName: currentApp?.name,
    })
  }, [currentApp?.name, growthBook])

  useEffect(() => {
    if (loading || !user) {
      // still loading, do nothing
      // admin doesn't have an error page so do nothing for errors too. logs will show the error
      return
    }

    const handleRouteChangeComplete = () => {
      // Refresh the user session after route change is complete
      refreshSession()
    }

    // Listen to the 'routeChangeComplete' event
    router.events.on("routeChangeComplete", handleRouteChangeComplete)
    router.events.off("routeChangeComplete", handleRouteChangeComplete)
    if (!user.organizations?.length && !user.apps?.length) {
      router.push("/get-started")
      return
    }
    const requestedOrg =
      router.query.appClient && user.apps.find((org) => org?.id === router.query.appClient)

    const localStorageAppId = localStorage.getItem("currentApp")
    const localStorageOrg =
      localStorageAppId && user.apps.find((org) => org?.id === localStorageAppId)
    if (!user.apps.length && user.organizations.length) {
      setCurrentOrganization(user.organizations[0] as Organization)
    } else if (requestedOrg) {
      setCurrentApp(requestedOrg)
      setCurrentOrganization(
        user.organizations?.find((org) => org.id === requestedOrg.organization.id)
      )
    } else if (localStorageOrg) {
      setCurrentApp(localStorageOrg)
      setCurrentOrganization(localStorageOrg?.organization)
    } else if (user.apps.length) {
      setCurrentApp(user.apps[0])
      setCurrentOrganization(
        user.organizations?.find((org) => org.id === user.apps[0]?.organization.id)
      )
      // Dont push for internal routes
      if (!router.pathname.startsWith("/app/internal")) {
        // Update url to use first org as we couldn't find org from url
        router.push(RouteTable.homeRoute(user.apps[0]?.id), null, { shallow: true })
      }
    }
    // ignoring router as it causes this to run every route
  }, [loading, user])

  useEffect(() => {
    if (loading || !user || user?.apps.length === 0) {
      return
    }

    if (!hasAccess(role, getUserRole(user))) {
      // No access
      router.push("/unauthorized")
    }
  }, [loading, role, router, user])

  const toast = useToast()
  const setCurrentOrganizationAndApp = (organization: Organization) => {
    const newApp = user.apps.find((org) => org.organization.id === organization.id)
    if (!newApp) {
      toast({
        title: "No apps found",
        description: `You are not in any apps for this Organization`,
        status: "info",
        position: "bottom-right",
      })
      return
    }
    setCurrentApp(newApp)
    setCurrentOrganization(organization)

    if (newApp) {
      setCurrentApp(newApp)
    }
    router.push(`/app/org/${newApp?.id}/manage/organization`)
  }

  const currentOrganizationApps = useMemo(
    () =>
      user?.apps
        .filter((org) => org.organization?.id === currentOrganization?.id)
        ?.map((item) => item) ?? [],
    [currentOrganization?.id, user?.apps]
  )

  const currentOrganizationRole = useMemo(
    () => currentOrganization?.members?.find((member) => member.id === user?.id)?.organizationRole,
    [currentOrganization?.members, user?.id]
  )

  useEffect(() => {
    if (session && !loading && user) {
      setShowModal(true)
    }
  }, [session, loading, user])
  return (
    <>
      <TosModal isOpen={showModal} onClose={() => setShowModal(false)} />
      <AppContext.Provider
        value={{
          session,
          currentApp,
          currentOrganization,
          currentOrganizationApps,
          currentOrganizationRole,
          user,
          loading,
          setCurrentApp,
          setCurrentOrganization,
          setCurrentOrganizationAndApp,
        }}
      >
        {children}
      </AppContext.Provider>
    </>
  )
}
