import { Text } from "@chakra-ui/react"
import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from "react-joyride"
import { useWalkthroughContext } from "src/hooks/core/useWalkthroughContext"

const JoyrideNoSSR = dynamic(() => import("react-joyride"), { ssr: false })

const CONTRACT_STEPS: Step[] = [
  {
    target: "#contractName",
    content: (
      <>
        <Text fontSize="md" fontWeight="normal">
          Enter an official name for your contract before deploying it on the blockchain.
        </Text>
      </>
    ),
    disableBeacon: true,
    spotlightClicks: true,
  },
  {
    target: "#deployContract",
    content: (
      <>
        <Text fontSize="md" fontWeight="normal">
          Click this button to deploy your contract on the blockchain.
        </Text>
      </>
    ),
    disableBeacon: true,
    spotlightClicks: true,
  },
  {
    target: "#addTeam",
    content: (
      <>
        <Text fontSize="md" fontWeight="normal">
          Add more teammates to manage your app.
        </Text>
      </>
    ),
    disableBeacon: true,
    spotlightClicks: true,
  },
  {
    target: "#collectionsButton",
    content: (
      <>
        <Text fontSize="md" fontWeight="normal">
          Click this button to create your first set of collectibles after you have deployed your
          smart contract.
        </Text>
      </>
    ),
    disableBeacon: true,
    spotlightClicks: true,
  },
]

const SET_CREATE_STEPS: Step[] = [
  {
    target: "#newSet",
    content: (
      <>
        <Text fontSize="md" fontWeight="normal">
          Click this button to create a new set for your collectibles.
        </Text>
      </>
    ),
    disableBeacon: true,
    spotlightClicks: true,
  },
  {
    target: "#title",
    content: (
      <>
        <Text fontSize="md" fontWeight="normal">
          Enter a title for your first set of collectibles.
        </Text>
      </>
    ),
    disableBeacon: true,
    spotlightClicks: true,
  },
]

const COLLECTIBLE_CREATE_STEPS: Step[] = [
  {
    target: "#newCollectible",
    content: (
      <>
        <Text fontSize="md" fontWeight="normal">
          Click this button to create a new collectible for your set.
        </Text>
      </>
    ),
    disableBeacon: true,
    spotlightClicks: true,
  },
  {
    target: "#collectibleForm",
    content: (
      <>
        <Text fontSize="md" fontWeight="normal">
          Enter the details of your first collectible.
        </Text>
      </>
    ),
    disableBeacon: true,
    spotlightClicks: true,
  },
]

export default function Walkthrough() {
  const { running, stepIndex, steps, setSteps, setStepIndex, setRunning, walkthroughType } =
    useWalkthroughContext()
  const [continuous, setContinuous] = useState(true)
  const router = useRouter()
  const path = router.pathname
  useEffect(() => {
    if (!router.isReady) {
      return
    }
    switch (walkthroughType) {
      case "contract":
        setContinuous(true)
        setStepIndex(0)
        setSteps(CONTRACT_STEPS)
        break
      case "set":
        setContinuous(false)
        setStepIndex(0)
        setSteps(SET_CREATE_STEPS)
        if (path.includes("create")) {
          setStepIndex(stepIndex + 1)
        }
        break
      case "collectible":
        setContinuous(false)
        setStepIndex(0)
        setSteps(COLLECTIBLE_CREATE_STEPS)
        if (path.includes("create")) {
          setStepIndex(stepIndex + 1)
        }
        break
    }
  }, [path, walkthroughType, running])

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, type, status } = data
    if (
      ([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status) ||
      action === ACTIONS.CLOSE
    ) {
      setRunning(false)
      setStepIndex(0)
      localStorage.setItem(walkthroughType + "Walkthrough", "true")
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1)
      setStepIndex(nextStepIndex)
    }
  }

  return (
    <JoyrideNoSSR
      run={running}
      continuous={continuous}
      callback={handleJoyrideCallback}
      stepIndex={stepIndex}
      steps={steps}
      styles={{
        buttonBack: {
          color: "white",
        },
        options: {
          arrowColor: "#2D3748",
          backgroundColor: "#2D3748",
          primaryColor: "#4A5568",
          textColor: "#FFFFFF",
        },
      }}
    ></JoyrideNoSSR>
  )
}
