import { useContext } from "react"
import { WalkthroughContext } from "src/components/app/internal/walkthrough/WalkthroughProvider"

export function useWalkthroughContext() {
  const context = useContext(WalkthroughContext)
  if (!context) {
    throw new Error("useWalkthroughContext must be used within a app provider")
  }

  return context
}
