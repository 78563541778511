import { NiftoryClient } from "@niftory/sdk"
import { NiftoryProvider } from "@niftory/sdk/react"
import { useRouter } from "next/router"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useAuthContext } from "src/hooks/core/useAuthContext"
import { useNetworkContext } from "src/hooks/core/useNetworkContext"

const SDKEnvironmentMap = {
  dev: {
    testnet: "dev",
    mainnet: "test",
  },
  test: {
    testnet: "test",
    mainnet: "testnet",
  },
  staging: {
    testnet: "testnet",
    mainnet: "mainnet",
  },
  prod: {
    testnet: "testnet",
    mainnet: "mainnet",
  },
}

export const NiftoryClientProvider = ({ children }) => {
  const { session, isLoading } = useAuthContext()
  const { network } = useNetworkContext()
  const [apiKey, setApiKey] = useState("")
  const router = useRouter()

  const appClient = router.query?.appClient as string

  const fetchKeys = useCallback(async () => {
    const appId = appClient
    if (!appId) {
      return
    }
    if (!isLoading && session?.authToken) {
      const app = await client.getAppById(appId)
      setApiKey(app.credentials?.[0]?.apiKey)
    }
  }, [appClient, network, session])

  useEffect(() => {
    fetchKeys()
  }, [appClient, fetchKeys])
  const environmentName = SDKEnvironmentMap[process.env.NEXT_PUBLIC_ADMIN_ENV]?.[network] ?? network

  const client = useMemo(() => {
    return new NiftoryClient({
      environmentName,
      authToken: session?.authToken as string,
      appId: appClient,
      apiKey: apiKey,
    })
  }, [environmentName, session?.authToken, appClient, apiKey])

  return <NiftoryProvider client={client}>{children} </NiftoryProvider>
}
