import { Box, Heading, Stack } from "@chakra-ui/react"
import * as React from "react"

interface NoticeProps {
  title: string
  children: React.ReactNode
  headingColor?: string
  boxColor?: string
}

export const Notice = (props: NoticeProps) => {
  const { title, children, headingColor, boxColor } = props
  return (
    <Stack spacing={{ base: "3", md: "6" }} direction={{ base: "column", md: "row" }}>
      <Stack spacing="1">
        <Heading fontWeight="extrabold" fontSize="2xl" color={headingColor}>
          {title}
        </Heading>
        <Box color={boxColor}>{children}</Box>
      </Stack>
    </Stack>
  )
}
