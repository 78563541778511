import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react"
import { useAcceptAdminUserTosMutation } from "@niftory/sdk/react"
import { useState } from "react"
import { DateTosLastUpdated } from "src/constants/TOS"
import { useAdminUserSession } from "src/hooks/core/useAdminUserSession"

import Terms from "./Terms"

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const TosModal = ({ isOpen, onClose }: Props) => {
  const { user } = useAdminUserSession()
  const [acceptTos, setAcceptTos] = useState(false)
  const hasAcceptedTos = new Date(user?.tosAcceptanceDateTime) > DateTosLastUpdated

  const [_, executeMutation] = useAcceptAdminUserTosMutation()

  const handleAccept = async () => {
    setAcceptTos(true)

    try {
      await executeMutation({})
    } catch (error) {
      console.error("Failed to update user:", error)
    }
    onClose()
  }

  const shouldOpenModal = !hasAcceptedTos && !acceptTos

  return (
    <Modal
      size="full"
      isOpen={shouldOpenModal && isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Terms />
        </ModalBody>
        <ModalFooter height={16}>
          <Popover isOpen={!acceptTos} placement="left">
            <PopoverTrigger>
              <Button
                size={"lg"}
                variant="outline"
                borderColor="gray.300"
                _hover={{ bg: "purple.50", border: "1px solid purple" }}
                onClick={handleAccept}
              >
                Accept
              </Button>
            </PopoverTrigger>
            <PopoverContent maxW="290">
              <PopoverArrow />
              <PopoverBody>
                Please read through the Niftory Terms of Service before Accepting
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default TosModal
