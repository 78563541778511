import { Box, Heading, SimpleGrid, Text } from "@chakra-ui/react"
import { format } from "date-fns"
import * as React from "react"
import { DateTosLastUpdated } from "src/constants/TOS"

import { Notice } from "./Notice"
export const Terms = () => {
  const formattedDate = format(DateTosLastUpdated, "MMMM dd, yyyy")

  return (
    <Box h="100%" w="100%" bg="page.background" color="page.text" p="12">
      <Box as="section" maxW="5xl" mx="auto" py="12" px={{ base: "6", md: "8" }}>
        <Heading width="100%" textAlign="center" color="page.text" fontSize="4xl" pb="12">
          NIFTORY SERVICES AGREEMENT
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 1 }} spacingX="10" spacingY={{ base: "8", md: "14" }}>
          <Notice title="">
            <strong>Last updated: {formattedDate}</strong>
            <Text mt={4}>
              This Niftory Services Agreement (including any Order Information, as amended and in
              effect from time to time, this “Agreement”), is entered into between Niftory, Inc., a
              Delaware corporation (“<strong>Niftory</strong>”), and you (“<strong>Customer</strong>
              ,” “<strong>you</strong>” or “<strong>your</strong>”). Niftory and Customer may be
              referred to herein collectively as the “<strong>Parties</strong>” or individually as a
              “<strong>Party</strong>.”
            </Text>

            <Text mt={4}>
              THIS AGREEMENT TAKES EFFECT WHEN YOU CLICK THE “I AGREE” BUTTON BELOW OR BY ACCESSING
              OR USING THE SERVICES (the “Effective Date”). BY CLICKING ON THE “I AGREE” BUTTON
              BELOW OR BY ACCESSING OR USING THE SERVICES, YOU: (A) ACKNOWLEDGE THAT YOU HAVE READ
              AND UNDERSTAND THIS AGREEMENT; (B) REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT,
              POWER, AND AUTHORITY TO ENTER INTO THIS AGREEMENT AND, IF ENTERING INTO THIS AGREEMENT
              FOR AN ORGANIZATION, THAT YOU HAVE LEGAL AUTHORITY TO BIND THAT ORGANIZATION; AND (C)
              ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS.
            </Text>

            <Text mt={4} mb={-8}>
              IF YOU DO NOT AGREE TO THESE TERMS, PLEASE SELECT THE “I DISAGREE” BUTTON BELOW.IF YOU
              HAVE A SEPARATE WRITTEN AGREEMENT WITH NIFTORY FOR YOUR USE OF THE SERVICES, THIS
              AGREEMENT WILL NOT APPLY TO YOU UNLESS THE WRITTEN AGREEMENT DOES NOT COVER A
              PARTICULAR SERVICE, IN WHICH CASE THIS AGREEMENT WILL APPLY SOLELY TO YOUR USE OF THAT
              PARTICULAR SERVICE. UNLESS YOU HAVE A SEPARATE WRITTEN AGREEMENT IN PLACE WITH NIFTORY
              FOR YOUR USE OF THE SERVICES, IF YOU DO NOT ACCEPT THESE TERMS YOU MAY NOT ACCESS OR
              USE THE SERVICES.
            </Text>
          </Notice>

          <Notice title="">
            <Heading fontWeight="extrabold" fontSize="2xl" color="page.text">
              1. DEFINITIONS.
            </Heading>
            <Text mt={4}>
              “<strong>Services</strong>” means, collectively, the products and services provided by
              Niftory that (a) you use, including without limitation products and services that are
              on a trial basis or otherwise free of charge, or (b) that you order under an Order
              Document. Services includes products and services that provide platform and hosting
              services, including access to any application programming interface, communications,
              and storage services used in connection therewith.
            </Text>
            <Text mt={4}>
              “<strong>Documentation</strong>” means Niftory&#39;s documentation, including user
              guide, help information and other documentation and information regarding the
              Services.
            </Text>
            <Text mt={4}>
              “<strong>Customer Blockchain Application</strong>” means any software application or
              service that you make available to your end users that interfaces with the Service.
            </Text>
            <Text mt={4}>
              “<strong>Customer Digital Collectibles</strong>” mean the non-fungible tokens or other
              customer-generated applications created using the Service and implemented on a
              blockchain platform.
            </Text>
            <Text mt={4} mb={6}>
              “<strong>Customer Products</strong>” means, collectively, the Customer Blockchain
              Application and the Customer Digital Collectibles.
            </Text>
            <Heading fontWeight="extrabold" fontSize="2xl" color="page.text">
              2. SERVICES.
            </Heading>
            <Text mt={6}>
              <strong>2.1 Provision of the Services</strong>. Niftory will: (a) provide the Services
              to you pursuant to this Agreement, the applicable Documentation, and any applicable
              Order Information, and in accordance with laws applicable to Niftory&#39;s provision
              of the Services to its customers generally (i.e., without regard for your particular
              use of the Services), subject to your use of the Services in accordance with this
              Agreement, the applicable Documentation, and any applicable Order Information; and (b)
              use commercially reasonable efforts to provide you with applicable support for the
              Services.
            </Text>
            <Text mt={4}>
              <strong>2.2 Customer Responsibilities</strong>. You will: (a) be solely responsible
              for all use of the Services and Documentation under your account and in connection
              with each Customer Product; (b) not transfer, resell, lease, license, or otherwise
              make available the Services to third parties (except to make the Customer Products
              available to your end users in connection with the sale or licensed use of each
              Customer Product as permitted herein) or offer them on a standalone basis; (c) use the
              Services only in accordance with this Agreement, the applicable Documentation, any
              applicable Order Information, and applicable law or regulation; (d) be solely
              responsible for all acts, omissions, and activities of your end users, including their
              compliance with this Agreement, the applicable Documentation, any applicable Order
              Information, and applicable law or regulation; (e) use commercially reasonable efforts
              to prevent unauthorized access to or use of the Services and notify Niftory promptly
              of any such unauthorized access or use; (f) provide reasonable cooperation regarding
              information requests from law enforcement, regulators, or telecommunications
              providers; and (g) comply with your representations and warranties set forth in this
              Agreement.. Customer hereby agrees to indemnify and hold harmless Niftory against any
              damages, losses, liabilities, settlements and expenses (including without limitation
              costs and attorneys&#39; fees) in connection with any claim or action that arises from
              an alleged violation of the foregoing.
            </Text>
            <Text mt={4}>
              <strong>2.3 Limitations</strong>. Niftory will not be responsible or liable for any
              failure in the Services resulting from or attributable to: (a) Customer&#39;s failure
              to use the Services in accordance with Niftory&#39;s requirements, including without
              limitation with instructions included in the Documentation; (b) failures in any
              telecommunications, network or other service or equipment that are not within
              Niftory&#39;s reasonable control; (c) Customer&#39;s delivery of video clips, images,
              text or other content (the <strong>“Customer Content”</strong>) to Niftory to perform
              the Services or create Customer&#39;s Products or provide the Services; (d) any force
              majeure or other cause beyond Niftory&#39;s reasonable control; (e) scheduled
              maintenance, if reasonable notice is provided; or (f) unauthorized access, breach of
              firewalls or other hacking by third parties, except to the extent such access, breach
              or hacking is caused by Niftory&#39;s negligence or willful misconduct.
            </Text>
            <Text mt={4}>
              <strong>2.4 Outages and Service Suspensions</strong>. Niftory will be responsible and
              liable solely for Niftory&#39;s own infrastructure failures in accordance with its
              SLA. However, in no event, will Niftory be liable for a blockchain network&#39;s own
              failure which includes, but is not limited to, a blockchain network not being able to
              generate keys, network congestion and software bugs. Niftory will be responsible for
              Customer&#39;s lost Customer Digital Collectibles if any of the following occurs: (a)
              Niftory&#39;s infrastructure fails to switch from one region to another region due to
              a region-based outage; (b) Niftory&#39;s infrastructure fails to switch from one
              infrastructure provider to another infrastructure provider due to an infrastructure
              provider-based outage; (c) any memory leaks in the blockchain network software; and
              (d) single, isolated faults in the blockchain network software causing process crashes
              (but excluding recurring bugs or issues that regularly arise in the blockchain network
              software) (each, a <strong>“Service Default”</strong> and, collectively, the{" "}
              <strong>“Service Defaults”</strong>). In the event that any Service Default occurs,
              Niftory will reimburse Customer for any Customer Digital Collectible lost that
              Customer provides reasonable evidence were lost during the occurrence of such Service
              Default. Such reimbursement shall be Customer&#39;s sole remedy in connection with any
              damages or losses occurring as a result of a Service Default, unless other direct
              damages are suffered by Customer.
            </Text>
            <Text mt={4} mb={6}>
              Niftory may suspend the Services immediately upon written notice to you for cause if
              Niftory, in good faith, determines: (a) that you or your end users materially breach
              (or Niftory, in good faith, believes that you or your end users have materially
              breached) any provision of this Agreement,; (b) there is an unusual and material spike
              or increase in your use of the Services and that such traffic or use is fraudulent or
              materially and negatively impacting the operating capability of the Services; (c) that
              its provision of the Services is prohibited by applicable law or regulation; (d) there
              is any use of the Services by you or your end users that threatens the security,
              integrity, or availability of the Services; or (e) that information in your account is
              untrue, inaccurate, or incomplete.
            </Text>
            <Heading fontWeight="extrabold" fontSize="2xl" color="page.text">
              3. CONDITIONS SPECIFIC TO WALLET SERVICES.
            </Heading>
            <Text mt={6}>
              <strong>3.1 Description of the Wallet Services</strong>. The Wallet Services consist
              of the following:
            </Text>
            <Text mt={4}>
              3.1.1 The Wallet application allowing users to perform the following actions in
              respect of a Wallet: (i) create a new Wallet; (ii) manage the functions of this
              Wallet; and (iii) initiate a transaction on the blockchain (a{" "}
              <strong>“Transaction”</strong>).
            </Text>
            <Text mt={4}>
              3.1.2 Transaction services. You will be able to specify addresses on the Flow Network.
              and can make a Transaction by selecting a Wallet, optionally choosing a destination
              address and any Digital Collectible or non-currency data you want to store or transfer
              and authorize with your API secret. Once authorized, you send the Transaction to the
              blockchain. To make a Transaction, the third-party application can use the Niftory API
              to send the Transaction to Niftory. Niftory executes the blockchain itself, depending
              on the parameters specified in the API call.
            </Text>
            <Text mt={4}>
              3.1.3 Communication linking. You will optionally be able to link addresses on the Flow
              Network to your communication channels like mobile number and email address. Linking
              these addresses to contact details will make it possible for Niftory to send Digital
              Collectibles to an email address or a mobile phone number instead of directly to a
              blockchain address as Niftory makes the translation.
            </Text>
            <Text mt={4}>
              <strong>3.2 Specific Terms Related to the Wallet Services.</strong>
            </Text>
            <Text mt={4}>
              3.2.1 The Wallet provides functionalities that allows you to store Digital
              Collectibles or non-currency data and to make Transactions via instructions to
              Niftory. Customers must ensure that their instructions to Niftory conform to the
              applicable rules of the blockchain.
            </Text>
            <Text mt={4}>
              3.2.2 The only authentic record of a Transaction is its recording in the blockchain
              associated with the concerned Digital Collectibles or non-currency data. Transactions
              must first be confirmed and recorded in the blockchain and this requires validation by
              the blockchain network.
            </Text>
            <Text mt={4}>
              3.2.3 The blockchain technology is based on a decentralized, peer-to-peer network or
              database supported by independent third parties, and is not controlled, owned nor
              operated by Niftory. Accordingly, Niftory cannot and does not guarantee that
              Transactions you initiate using the Services shall be confirmed and recorded in the
              blockchain associated with the concerned Digital Collectibles nor that once stored,
              they remain on the blockchain. Conflicts between recordings in the blockchain cannot
              be excluded, which means that a user&#39;s Transaction may not or no longer be
              recorded in the blockchain associated with the concerned Digital Collectibles.
            </Text>
            <Text mt={4}>
              3.2.4 Niftory will use its best efforts to have the Transaction you initiate validated
              and recorded in this blockchain. Niftory, however, cannot and does not guarantee that
              the Wallet reflects all Transactions or (any change in) title to or right on any
              Digital Collectibles.
            </Text>
            <Text mt={4}>
              3.2.5 By initiating an instruction for Transaction, you authorize Niftory to submit
              your Transaction to the blockchain network for validation and recording on the
              concerned blockchain. Once a Transaction has been submitted to the relevant blockchain
              network, the blockchain network will automatically record or reject the Transaction
              and neither you nor Niftory will be able to cancel or otherwise modify the
              Transaction. After being submitted for recording in the blockchain associated with the
              concerned Digital Collectibles, a Transaction may be unconfirmed for a period of time.
              A Transaction is not complete while it is in a pending state.
            </Text>
            <Text mt={4}>
              3.2.6 Wallet Services offered by Niftory are not intended to store or process
              currency, with the exception of any currency required to upload or transfer Digital
              Collectibles on the blockchain. You shall not use or modify the Wallet Service to
              store or process currency except as contemplated in this Section.
            </Text>
            <Text mt={4}>
              3.2.7 Niftory only performs services to enable Transactions on the blockchain and has
              no title, rights or interest in the Digital Collectibles that are the subject of your
              Transactions. You bear the risk of any Transaction not being recorded in the
              blockchain associated with the concerned Digital Collectibles or for any delay or
              conflicts in recordings. You choose the Digital Collectible(s) to which your
              Transactions relate as long as they are supported by Niftory and the counterparty for
              the Transaction. You are exclusively responsible for the Digital Collectibles you and
              your counterparty choose.
            </Text>
            <Text mt={4} mb={6}>
              3.2.8 You are solely responsible for ensuring all Transaction details are correct, and
              you must carefully verify all transaction information prior to submitting transaction
              instructions. To the extent the Transaction is made as counterparty for the delivery
              of goods or services from a third party, Niftory&#39;s Services do not cover and
              Niftory undertakes no responsibility in relation to the delivery, quality or any other
              aspects of any such goods or service. You are responsible for loss of blockchain
              objects (including keys, except under Niftory&#39;s safeguarding) or Digital
              Collectibles. In case of API integration you will make sure that you only share your
              API keys or secrets with third-party applications you trust and you are responsible
              for any misuse of such keys or secrets.
            </Text>
            <Heading fontWeight="extrabold" fontSize="2xl" color="page.text">
              4. PROPRIETARY RIGHTS.
            </Heading>
            <Text mt={6}>
              <strong>4.1 Ownership Rights</strong>. As between the parties, Niftory exclusively
              owns and reserves all right, title, and interest in and to the Services, the
              Documentation, Niftory&#39;s Confidential Information (as defined below), Service
              Usage Data, and any feedback or suggestions you or your end users provide regarding
              the Services. As between the parties, you exclusively own and reserve all right,
              title, and interest in and to the Customer Products, your Confidential Information,
              and Customer Data, subject to Niftory&#39;s rights to process Customer Data in
              accordance with this Agreement.
            </Text>
            <Text mt={4}>
              <strong>4.2 Customer Data and Aggregate Usage Data</strong>. You grant Niftory and its
              affiliates the right to process Customer Data as necessary to provide the Services in
              a manner that is consistent with this Agreement and the Niftory Privacy Notice. You
              are responsible for the quality and integrity of Customer Data. Customer expressly
              acknowledges and agrees that Niftory may collect and retain information on the use,
              contents and performance of the Services in a depersonalized or aggregated form but
              not in a way that would identify Customer personally, or any of Customer&#39;s
              customers.
            </Text>
            <Text mt={4}>
              <strong>4.3 Restrictions</strong>. Except as expressly permitted in this Agreement,
              Customer shall not directly or indirectly: (a) use any of Niftory&#39;s Confidential
              Information (as defined below) to create any service, software, documentation or data
              that is similar to any aspect of the Services, provided that the foregoing does not
              prohibit independent development of similar functionality by Customer or third
              parties; (b) disassemble, decompile, reverse engineer or use any other means to
              attempt to discover any source code of the Services, or the underlying ideas,
              algorithms or trade secrets therein, except as permitted by Niftory in writing; (c)
              encumber, sublicense, transfer, rent, lease, time-share or use the Services in any
              service bureau arrangement or otherwise for the benefit of any third party, except as
              permitted by Niftory in writing; (d) copy, distribute, manufacture, adapt, create
              derivative works of, translate, localize, port or otherwise modify any aspect of the
              Services; (e) use or allow the transmission, transfer, export, re-export or other
              transfer of any product, technology or information it obtains or learns pursuant to
              this Agreement (or any direct product thereof) in violation of any export control or
              other laws and regulations of the United States or any other relevant jurisdiction; or
              (f) knowingly permit any third party to engage in any of the foregoing proscribed
              acts.
            </Text>
            <Text mt={4}>
              <strong>4.4 Digital Collectibles and Customer Content</strong>. Customer Content will
              be incorporated into the Digital Collectibles Niftory creates for Customer and will be
              made available to registered users of Niftory&#39;s website for sale or exchange. By
              contributing Customer Content to Niftory, Customer acknowledges and agrees to the
              following terms, representations and warranties. Customer should only contribute
              Customer Content if Customer is comfortable with the following terms:
            </Text>
            <Text mt={4}>
              4.4.1 Customer Content that is unlawful may be flagged and removed without notice. If
              Customer content is removed and not obviously unlawful, Customer shall have the right
              to appeal the decision to a mutually agreeable arbitrator.
            </Text>
            <Text mt={4}>
              4.4.2 All Customer Content you provide to Niftory must comply with all applicable laws
              (including, without limitation, U.S. copyright law). Customer represents and warrants
              that (i) Customer owns or otherwise possesses all rights to use Customer Content; (ii)
              Customer has the permission to use the name and likeness of each identifiable
              individual person and to use such individual&#39;s identifying or personal
              information; (iii) Customer is authorized to grant all of the rights described in this
              Agreement; and (iv) the use of Customer Content as contemplated by this Agreement will
              not infringe or violate any intellectual property, privacy, publicity, contract or
              other rights of any person or entity.
            </Text>
            <Text mt={4}>
              4.4.3 Customer will be held solely responsible and liable for Customer Content and
              conduct using the Services in accordance with this Agreement and with Niftory&#39;s
              Terms of Service governing all authorized users of the Service, including Customer.
              Niftory will not be responsible or liable for any use of Customer Content by Niftory
              and Customer represents and warrants that Customer has all the rights, power and
              authority necessary to grant the rights granted herein to any Customer Content that
              Customer submits.
            </Text>
            <Text mt={4}>
              <strong>4.4.4</strong> Customer Content may be modified or adapted (for example,
              videos clips may be edited or photos may be cropped) in order to meet design and
              technical requirements for Digital Collectibles or for any other reason. Customer is
              responsible for retaining original copies of Customer Content uploaded from a local
              source.
            </Text>
            <Text mt={4}>
              By uploading, posting, contributing or otherwise providing Customer Content to Niftory
              for purposes of generating the Digital Collectibles, Customer grants Niftory a
              limited, nonexclusive, worldwide perpetual and fully paid up license to use the
              Customer Content solely for the purpose of generating the Digital Collectibles as
              described herein
            </Text>
            <Text mt={4} mb={6}>
              <strong>4.5 Trademark and Publicity Rights</strong>. Customer hereby grants Niftory
              the right to use Customer&#39;s company or business name and current and future
              trademarks, service marks and trade names (“Marks”) on the Niftory website or in
              Niftory marketing and sales materials solely for the purpose of identifying Customer
              as a Niftory affiliate or customer, subject to usage guidelines or pre-approvals or
              authorizations that Customer may provide to Niftory in writing. Niftory shall not use
              the Marks for any other purpose without first obtaining Customer&#39;s written
              consent, which shall not be unreasonably withheld.
            </Text>
            <Heading fontWeight="extrabold" fontSize="2xl" color="page.text">
              5. CONFIDENTIALITY.
            </Heading>
            <Text mt={6} mb={6}>
              {" "}
              As used in this Agreement, <strong>“Confidential Information”</strong> means
              information and materials provided by the disclosing Party (
              <strong>“Discloser”</strong>) to the Party receiving such information or materials (
              <strong>“Recipient”</strong>) that (a) are identified as confidential at the time of
              disclosure, or (b) a reasonable person in the relevant industries should understand to
              be confidential based on the nature of the information and materials and all other
              relevant factors. For the avoidance of doubt, each Party agrees that the business,
              technical and financial information, that is designated in writing as confidential, or
              is disclosed in a manner that a reasonable person would understand the confidentiality
              of the information disclosed, shall be the confidential property of the Discloser and
              its licensors. For avoidance of doubt, the existence of this business relationship,
              this Agreement, and all subject matters within the relationship of the parties are
              Confidential Information. Confidential Information does not include information that
              (a) is previously rightfully known to the Recipient without restriction on disclosure;
              (b) is or becomes known to the general public through no act or omission on the part
              of the Recipient; (c) is disclosed to the Recipient without restriction on disclosure
              by a third party without breach of any separate nondisclosure obligation; or (d) is
              independently developed by the Recipient without use of or reference to any
              Confidential Information of the Discloser. Except as expressly and unambiguously
              allowed herein, the Recipient will hold in confidence and not use or disclose any
              Confidential Information. The Recipient shall protect the confidentiality of the
              Discloser&#39;s Confidential Information in the same manner that it protects the
              confidentiality of its own confidential information of like kind (but in no event
              using less than reasonable care). Notwithstanding the foregoing, each party may
              disclose the Confidential Information of the other party to its employees, consultants
              or other agents who have a bona fide need to know for such Confidential Information;
              provided, that each such employee, consultant or agent is bound by confidentiality
              obligations at least as protective as those set forth herein. Each Party shall be
              responsible for any breach of confidentiality by its employees, consultants and
              agents. Niftory&#39;s disclosure of the existence of this business relationship and
              the terms (except for the volume of digital assets on any Hosted Service) and
              existence of this Agreement, solely in connection with a bona fide due diligence
              inquiry for a financing, acquisition or similar transaction, subject to
              confidentiality obligations consistent with those herein, shall not constitute a
              breach of its obligations pursuant to this Section 5. Upon the expiration or
              termination of this Agreement, all of the Confidential Information (including any
              copies or extracts thereof) will be returned to the Discloser, and the Recipient will
              make no further use of such materials. Promptly after any request by Discloser,
              Recipient will (x) destroy or return to Discloser all Confidential information and
              materials in Recipient&#39;s possession or control, and (y) upon written request by
              Discloser, confirm such return/destruction in writing. If required by law, the
              Recipient may disclose Confidential Information of the Discloser but will give prompt
              prior notice of such disclosure to the Discloser to permit the Discloser to intervene
              and to request protective orders or other confidential treatment therefor, if
              permitted by law.
            </Text>
            <Heading fontWeight="extrabold" fontSize="2xl" color="page.text">
              6. PAYMENTS.
            </Heading>
            <Text mt={6} mb={6}>
              Niftory may make the Services available to Customer free of charge for a limited
              experience (i.e., Testnet usage) or period, after which point Customer shall have the
              option to convert to a paid subscription. Payment terms and conditions shall be agreed
              upon separately in writing and referenced in the applicable Order Information. All
              Fees and other amounts payable by Customer under this Agreement are exclusive of taxes
              and similar assessments. Customer is responsible for all sales, use, and excise taxes,
              and any other similar taxes, duties, and charges of any kind imposed by any federal,
              state, or local governmental or regulatory authority on any amounts payable by
              Customer hereunder, other than any taxes imposed on Niftory&#39;s income.
            </Text>
            <Heading fontWeight="extrabold" fontSize="2xl" color="page.text">
              7. LIMITED WARRANTY AND DISCLAIMERS.
            </Heading>
            <Text mt={6}>
              <strong>7.1 General.</strong> Each party represents and warrants that: (a) it is a
              duly organized and validly existing under the laws of the jurisdiction in which it is
              organized; (b) it has full power and authority, and has obtained all approvals,
              permissions and consents necessary, to enter into this Agreement and to perform its
              obligations hereunder; (c) this Agreement is legally binding upon it and enforceable
              in accordance with its terms; and (d) the execution, delivery and performance of this
              Agreement does not and will not conflict with any agreement, instrument, judgment or
              understanding, oral or written, to which it is a party or by which it may be bound.
            </Text>
            <Text mt={4} mb={4}>
              <strong>7.2 Additional Niftory Commitments for Paid Services.</strong> With respect to
              paid Services, Niftory represents and warrants to Customer that (a) the Services will
              be provided in a professional and workmanlike manner in accordance with commercially
              reasonable industry standards; and (b) the Services will perform substantially in
              accordance with the Documentation in all material respects. If Niftory breaches any
              warranties in this Section, Customer&#39;s exclusive remedy and Niftory&#39;s sole
              obligation will be for Niftory to make reasonable efforts to correct the
              non-conformity or, if Niftory is unable to correct the non-conformity within fifteen
              (15) days after receipt of Customer&#39;s written notice, for Customer to terminate
              the applicable Order Information and receive a refund, on a pro rata basis, or any
              fees prepaid in such Order Information that are unused as of the termination effective
              date.
            </Text>
            <Text mt={4} mb={4}>
              <strong>7.3 Services Generally.</strong> Customer acknowledges and agrees that factors
              beyond Niftory&#39;s reasonable control, including without limitation any force
              majeure event or Customer&#39;s failure to use the Services in accordance with
              Niftory&#39;s requirements, may interfere with the Services. Niftory hereby disclaims
              (for itself and its suppliers) all responsibility for any lost Customer Products
              resulting from any act or omission other than the Service Defaults. Except as
              expressly provided herein, Niftory is not responsible for any tokens that Customer
              stakes or otherwise stores or transfers using the Services. Customer acknowledges and
              agrees that Customer shall access and use the Services and Customer Products at
              Customer&#39;s own risk.
            </Text>
            <Text mt={4} mb={4}>
              <strong>7.4 Disclaimers.</strong> EXCEPT TO THE EXTENT EXPRESSLY STATED IN SECTION 7
              OF THIS AGREEMENT: (A) NIFTORY AND ITS LICENSORS MAKE NO REPRESENTATIONS OR WARRANTIES
              OF ANY KIND, WHETHER EXPRESS, STATUTORY OR IMPLIED (IN FACT OR BY OPERATION OF LAW),
              REGARDING THE SERVICE, PROFESSIONAL SERVICES, OR ANY MATTER WHATSOEVER; AND (B)
              NIFTORY AND ITS LICENSORS DO NOT WARRANT THAT THE SERVICES ARE OR WILL BE ERROR-FREE,
              MEET CUSTOMER&#39;S REQUIREMENTS, ACHIEVE ANY PARTICULAR RESULTS, OR BE TIMELY OR
              SECURE. NIFTORY AND ITS LICENSORS EXPRESSLY DISCLAIM ALL IMPLIED WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT WITH RESPECT TO
              THE SERVICES, AND CUSTOMER HAS NO RIGHT TO MAKE OR PASS ON TO ANY THIRD PARTY ANY
              REPRESENTATION OR WARRANTY BY NIFTORY.
            </Text>
            <Text mt={4} mb={4}>
              THE SERVICE MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE
              USE OF THE INTERNET OR ELECTRONIC COMMUNICATIONS. NIFTORY IS NOT RESPONSIBLE FOR
              DELAYS, DELIVERY FAILURES, OR OTHER DAMAGE, LOSS OR LIABILITY RESULTING FROM SUCH
              PROBLEMS NOT CAUSED BY NIFTORY.
            </Text>
            <Text mt={4} mb={6}>
              CUSTOMER AGREES THAT ITS SUBSCRIPTION TO THE SERVICE AND FEES DUE OR PAID UNDER THIS
              AGREEMENT ARE NEITHER CONTINGENT ON THE DELIVERY OF ANY FUTURE FUNCTIONALITY OR
              FEATURES, NOR BASED ON ANY ORAL OR WRITTEN COMMENTS REGARDING ANY FUTURE FUNCTIONALITY
              OR FEATURES. CUSTOMER ACKNOWLEDGES THAT NIFTORY IS UNDER NO OBLIGATION TO PROVIDE AN
              OUTCOME SIMILAR TO OTHER SERVICE PROVIDERS THAT MAY PROVIDE A HIGHER RETURN. MORE
              GENERALLY, IN ENTERING INTO THIS AGREEMENT, NEITHER PARTY IS RELYING ON ANY OTHER
              COMMITMENTS, STATEMENTS OR OTHER MATTERS NOT EXPRESSLY ADDRESSED IN THIS AGREEMENT OR
              ORDER INFORMATION.
            </Text>
            <Heading fontWeight="extrabold" fontSize="2xl" color="page.text">
              8. INDEMNIFICATION.
            </Heading>
            <Text mt={6} mb={4}>
              8.1 <strong>Customer.</strong> Customer agrees to defend, indemnify, and hold harmless
              Niftory and its affiliates, officers, directors, employees, agents, shareholders,
              successors and permitted assigns thereof against any claim by a third party that is
              related to: (a) Customer&#39;s breach of any material representation, warranty,
              responsibility, covenant or other agreement made herein; and (b) the gross negligence
              or intentional misconduct of Customer, including but not limited to any liability
              arising solely from Customer&#39;s failure to abide by applicable law or regulatory
              requirements as reasonably known by the industry at the time the acts in question are
              committed.
            </Text>
            <Text mt={4} mb={4}>
              8.2 <strong>Niftory.</strong> Except as provided below, Niftory agrees to (a) defend
              Customer against any claim by a third party that the Services infringe a valid US
              patent (issued as of the Effective Date), or any copyright or trade secret, of such
              third party; and (b) indemnify Customer for damages, liabilities, costs and expenses
              (including reasonable attorneys&#39; fees) awarded by a court of competent
              jurisdiction in a final, non-appealable judgment, or agreed to in a written settlement
              agreement agreed in writing by Niftory, arising out of such claims; and (c) defend and
              indemnify Customer for the gross negligence or intentional misconduct of Niftory,
              including but not limited to any liability arising solely from Niftory&#39;s failure
              to abide by applicable law or regulatory requirements as reasonably known by the
              industry at the time the acts in question are committed. If any Service becomes or, in
              Niftory&#39;s opinion, is likely to become the subject of any injunction preventing
              its use as contemplated herein, Niftory may, at its option (i) obtain for Customer the
              right to continue using the Service; or (ii) replace or modify the Service so that it
              becomes non-infringing without substantially compromising its principal functions. If
              (i) and (ii) are not reasonably available to Niftory, then Niftory may (iii) terminate
              this Agreement upon written notice to Customer. The foregoing states the entire
              liability of Niftory, and Customer&#39;s exclusive remedy, with respect to any actual
              or alleged violation of intellectual property rights by the Services, any part thereof
              or its use or operation.
            </Text>
            <Text mt={4} mb={4}>
              8.3 <strong>Exclusions.</strong> Niftory shall have no liability or obligation
              hereunder with respect to any claim based upon: (a) use of any Services in an
              application or environment or on a platform or with devices or blockchain assets for
              which it was not designed or contemplated; (b) modifications, alterations,
              combinations or enhancements of the Services not created by or for Niftory; (c) any
              portion of the Services that implements Customer&#39;s requirements; (d)
              Customer&#39;s continuing allegedly infringing activity after being notified thereof
              or its continuing use of any version after being provided modifications that would
              have avoided the alleged infringement; or (e) any intellectual property right in which
              Customer or any of its affiliates has an interest.
            </Text>
            <Text mt={4} mb={6}>
              8.4 <strong>Procedures.</strong> Any claim for indemnification hereunder is contingent
              upon (a) the indemnified party promptly providing written notice of the claim and
              reasonable cooperation, information, and assistance in connection therewith; and (b)
              giving the defending/indemnifying party sole control and authority to defend, settle
              or compromise such claim. The indemnifying party shall not make any settlement that
              requires a materially adverse act or admission by the indemnified party without the
              indemnified party&#39;s written consent (such consent not to be unreasonably delayed,
              conditioned or withheld). The indemnifying party shall not be liable for any
              settlement made without its prior written consent.
            </Text>
            <Heading fontWeight="extrabold" fontSize="2xl" color="page.text">
              9. LIMITATION OF LIABILITY.
            </Heading>
            <Text mt={6} mb={6}>
              NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT, BUT ONLY TO THE MAXIMUM
              EXTENT PERMITTED BY APPLICABLE LAW: IN NO EVENT SHALL EITHER PARTY OR ITS SUPPLIERS,
              OFFICERS, REPRESENTATIVES, CONTRACTORS OR EMPLOYEES BE LIABLE CONCERNING THE SUBJECT
              MATTER OF THIS AGREEMENT, REGARDLESS OF THE FORM OF ANY CLAIM OR ACTION (WHETHER IN
              CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE), FOR ANY (A) LOSS OR INACCURACY
              OF DATA, LOSS OR INTERRUPTION OF USE, OR COST OF PROCURING SUBSTITUTE TECHNOLOGY,
              GOODS OR SERVICES; (B) INDIRECT, PUNITIVE, INCIDENTAL, RELIANCE, SPECIAL, EXEMPLARY OR
              CONSEQUENTIAL DAMAGES INCLUDING, BUT NOT LIMITED TO, LOSS OF REWARDS, LOSS OF
              OPPORTUNITIES, LOSS OF ECONOMIC ADVANTAGE, LOSS OF BOND, BUSINESS, REVENUES, PROFITS
              AND GOODWILL; OR (C) DAMAGES, IN THE AGGREGATE, IN EXCESS OF THE AMOUNT OF FEES PAID
              TO NIFTORY BY CUSTOMER DURING THE TWELVE (12) MONTHS PRECEDING SUCH CLAIMS, EVEN IF IT
              HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </Text>
            <Heading fontWeight="extrabold" fontSize="2xl" color="page.text">
              10. TERM AND TERMINATION.
            </Heading>
            <Text mt={6} mb={4}>
              10.1 <strong>Term.</strong> This Agreement shall commence on the Effective Date and
              shall continue in effect until terminated as set forth below.{" "}
            </Text>
            <Text mt={4} mb={4}>
              10.2 <strong>Termination.</strong> This Agreement may be terminated (in whole, or in
              respect of any Order Information) by a party: (a) if the other party materially
              breaches a provision of this Agreement and either the breach cannot be cured or, if
              the breach can be cured, the party fails to cure such breach within 30 days (10 days
              in the case of non-payment) after receiving written notice of such breach from the
              non-breaching party; or (b) as otherwise set forth in the Order Information.
            </Text>
            <Text mt={4} mb={6}>
              10.3 <strong>Effects of Termination.</strong> Upon any expiration or termination of
              any order or this Agreement, all corresponding rights, obligations and licenses of the
              parties shall cease, except that all obligations that accrued prior to the effective
              date of termination (including without limitation, all payment obligations) shall
              survive; and the provisions of Sections 4 (Proprietary Rights), 5 (Confidentiality), 6
              (Payments), 7 (Limited Warranty and Disclaimers), 8 (Indemnification), 9 (Limitation
              of Liability), 10 (General Provisions) and this Section 10.3 shall survive.
            </Text>
            <Heading fontWeight="extrabold" fontSize="2xl" color="page.text">
              11. GENERAL PROVISIONS.
            </Heading>
            <Text mt={6} mb={4}>
              11.1 <strong>Entire Agreement.</strong> This Agreement (including the Order
              Information) constitutes the entire agreement, and supersedes all prior or
              contemporaneous negotiations, discussions, understandings or agreements, whether oral
              or written, between the parties regarding the subject matter of this Agreement. Any
              inconsistent or additional terms on any related purchase order, confirmation or
              similar form, even if signed by the parties hereafter, shall have no effect under this
              Agreement. This Agreement may be executed in one or more counterparts, each of which
              shall be an original, but taken together constituting one and the same instrument.
              Execution of an electronic copy shall have the same force and effect as execution of
              an original, and an electronic signature shall be deemed an original and valid
              signature. No change, consent or waiver under this Agreement will be effective unless
              in writing and signed by the party against which enforcement is sought. The failure of
              either party to enforce its rights under this Agreement at any time for any period
              will not be construed as a waiver of such rights, and the exercise of one right or
              remedy will not be deemed a waiver of any other right or remedy. If any provision of
              this Agreement is determined to be illegal or unenforceable, that provision will be
              l̦imited or eliminated to the minimum extent necessary so that this Agreement will
              otherwise remain in full force and effect and enforceable.
            </Text>
            <Text mt={4} mb={4}>
              11.2 <strong>Governing Law and Dispute Resolution.</strong> This Agreement shall be
              governed by and construed in accordance with the laws of the State of Delaware, USA,
              without regard to conflict of law provisions of any jurisdiction. The Hosted Service
              is a service, not a good, and is not subject to the Uniform Commercial Code, the
              Uniform Computer Transactions Act, or the United Nations Convention on the
              International Sale of Goods. Exclusive jurisdiction and venue for actions related to
              this Agreement will be the state or federal courts located in King County, Washington
              having jurisdiction over Niftory&#39;s offices, and both parties&#39; consent to the
              jurisdiction of such courts with respect to any such action. Other than respect to
              seeking injunctive relief in connection with matters that qualify for such an
              extraordinary remedy under applicable law, neither Party may initiate any litigation
              against the other Party until after providing clear written notice of its intention to
              do so and first making a good faith effort to resolve the dispute informally through
              escalation to an appropriate level of executive management of both Parties for at
              least thirty (30) days after providing such notice. In any action or proceeding to
              enforce this Agreement, the prevailing party will be entitled to recover from the
              other party its costs and expenses (including reasonable attorneys&#39; fees) incurred
              in connection with such action or proceeding and enforcing any judgment or order
              obtained.
            </Text>
            <Text mt={4} mb={4}>
              11.3 <strong>Remedies.</strong> Except as specifically provided otherwise herein, each
              right and remedy in this Agreement is in addition to any other right or remedy, at law
              or in equity. Each party agrees that, in the event of any breach or threatened breach
              of Section 4 or 5, the non-breaching party will suffer irreparable damage for which it
              will have no adequate remedy at law. Accordingly, the non-breaching party shall be
              entitled to injunctive and other equitable remedies to prevent or restrain such breach
              or threatened breach, without the necessity of posting any bond.
            </Text>
            <Text mt={4} mb={4}>
              11.4 <strong>Notices.</strong> All notices under this Agreement will be in writing and
              delivered to the parties at their respective addresses stated herein or at such other
              address designated by written notice. Notices will be deemed to have been duly given
              when received, if personally delivered; when receipt is electronically confirmed, if
              transmitted by email; the day after being sent, if sent for next day delivery by
              recognized overnight delivery service; or upon receipt, if sent by certified or
              registered mail, return receipt requested.
            </Text>
            <Text mt={4} mb={4}>
              11.5 <strong>Force Majeure.</strong> In the event that either party is prevented from
              performing, or is unable to perform, any of its obligations under this Agreement
              (other than payment obligations) due to any cause beyond its reasonable control, e.g.
              war, riots, labor unrest, fire, earthquake, flood, hurricane, epidemic, other natural
              disasters and acts of God the affected party shall give written notice thereof to the
              other party and its performance shall be extended for the period of delay or inability
              to perform due to such occurrence.
            </Text>
            <Text mt={4} mb={4}>
              11.6 <strong>Assignment.</strong> This Agreement and the rights and obligations
              hereunder may not be assigned, in whole or in part, by either party without the other
              party&#39;s written consent, not to be unreasonably withheld. However, without
              consent, either party may assign this Agreement to any successor to all or
              substantially all of its business that concerns this Agreement (whether by sale of
              assets or equity, merger, consolidation or otherwise). This Agreement shall be binding
              upon, and inure to the benefit of, the successors, representatives and assigns (as
              defined herein) of the parties hereto.
            </Text>
            <Text mt={4} mb={4}>
              11.7 <strong>Independent Contractors.</strong> The parties shall be independent
              contractors under this Agreement, and nothing herein will be construed as having
              created either party as the employer, employee, agent or representative of the other
              party, or both parties as having created a joint venture or other form of joint
              enterprise, or fiduciary relationship between the parties.
            </Text>
          </Notice>
        </SimpleGrid>
      </Box>
    </Box>
  )
}

export default Terms
