import { FeatureDefinition, GrowthBook } from "@growthbook/growthbook"
import axios from "axios"

type FeatureDefinitions = { features: Record<string, FeatureDefinition> }

export async function getGrowthBook() {
  const growthbook = new GrowthBook({
    // Add more attributes to gate by here
    attributes: {
      clientId: process.env.NEXT_PUBLIC_CLIENT_ID,
    },
  })

  const response = await axios.get<FeatureDefinitions>(
    `https://cdn.growthbook.io/api/features/${process.env.NEXT_PUBLIC_GROWTHBOOK_API_KEY}`
  )

  growthbook.setFeatures(response.data.features)

  return growthbook
}
