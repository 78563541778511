import React, { createContext, useMemo, useState } from "react"
import { Step } from "react-joyride"

export type WalkthroughType = "contract" | "collectible" | "set"

export interface AppState {
  running: boolean
  stepIndex: number
  steps: Step[]
  walkthroughType: WalkthroughType
}

const appState: AppState = {
  running: false,
  stepIndex: 0,
  steps: [],
  walkthroughType: null,
}

interface IWalkthroughContext {
  running: AppState["running"]
  stepIndex: AppState["stepIndex"]
  steps: AppState["steps"]
  walkthroughType: WalkthroughType
  setRunning: React.Dispatch<React.SetStateAction<AppState["running"]>>
  setSteps: React.Dispatch<React.SetStateAction<AppState["steps"]>>
  setStepIndex: React.Dispatch<React.SetStateAction<AppState["stepIndex"]>>
  setWalkthroughType: React.Dispatch<React.SetStateAction<WalkthroughType>>
}

export const WalkthroughContext = createContext<IWalkthroughContext>({
  ...appState,
  setRunning: () => null,
  setSteps: () => null,
  setStepIndex: () => null,
  setWalkthroughType: () => null,
})

export function WalkthroughProvider({ children }: any) {
  const [running, setRunning] = useState<AppState["running"]>(appState.running)
  const [steps, setSteps] = useState<AppState["steps"]>(appState.steps)
  const [stepIndex, setStepIndex] = useState<AppState["stepIndex"]>(appState.stepIndex)
  const [walkthroughType, setWalkthroughType] = useState<WalkthroughType>(appState.walkthroughType)

  const value = useMemo(
    () => ({
      running,
      steps,
      stepIndex,
      walkthroughType,
      setRunning,
      setSteps,
      setStepIndex,
      setWalkthroughType,
    }),
    [running, steps, stepIndex, walkthroughType]
  )

  return <WalkthroughContext.Provider value={value}>{children}</WalkthroughContext.Provider>
}
