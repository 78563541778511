import { useFeature } from "@growthbook/growthbook-react"
import React, { createContext } from "react"

import { ServiceType } from "../utils/lambdaUtils"

interface IApiContext {
  useApi: boolean
  route: string
  service: ServiceType
}

export const ApiContext = createContext<IApiContext>({
  useApi: false,
  route: "admin",
  service: "db",
})

type Props = {
  featureFlag: string
  context?: Partial<IApiContext>
  children: React.ReactNode
}

export function ApiProvider({ featureFlag, context, children }: Props) {
  const useApiFeature = useFeature(featureFlag).on
  const useApiValue = context?.useApi != null ? context.useApi : useApiFeature
  const route = useApiValue ? "graphql" : context?.route ?? "admin"
  const service: ServiceType = useApiValue ? "api" : context?.service ?? "db"
  return (
    <ApiContext.Provider value={{ useApi: useApiValue, route, service }}>
      {children}
    </ApiContext.Provider>
  )
}
