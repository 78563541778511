import { useSession } from "next-auth/react"

import { useNetworkContext } from "./useNetworkContext"

/*
  Gets session of current network according to the context
*/
export const useNetworkSession = () => {
  const { data, status, update } = useSession()
  const { network } = useNetworkContext()

  if (data?.[`${network}:userId`]) {
    data.userId = data?.[`${network}:userId`]
  }

  if (data?.[`${network}:authToken`]) {
    data.authToken = data?.[`${network}:authToken`]
  }

  return {
    data: data ?? null,
    loading: status === "loading",
    status,
    update,
  }
}
