import { useMemo } from "react"
import { getGraphQLClient } from "src/lib/graphql/client"
import { NetworkType } from "src/lib/providers/NetworkProvider"
import { getApiKey, ServiceType } from "src/lib/utils/lambdaUtils"

import { useAuthContext } from "./useAuthContext"
import { useNetworkContext } from "./useNetworkContext"

export const useGraphQLClient = (route: string, service: ServiceType) => {
  const { session } = useAuthContext()
  const { network } = useNetworkContext()
  return useMemo(() => {
    const apiKey = getApiKey(service)
    const headers = {}

    if (apiKey) {
      headers["X-Niftory-API-Key"] = apiKey
    }
    if (session?.authToken) {
      headers["Authorization"] = `Bearer ${session.authToken}`
    }

    if (session?.[`${network}:authToken`]) {
      headers["Authorization"] = `Bearer ${session[`${network}:authToken`]}`
    }

    return getGraphQLClient(route, headers, service, network as NetworkType)
  }, [route, service, session, network])
}
