import React, { ReactNode } from "react"
import { useApiContext } from "src/hooks/core/useApiContext"
import { useGraphQLClient } from "src/hooks/core/useGraphQLClient"
import { Provider as UrqlProvider } from "urql"

import { ServiceType } from "../utils/lambdaUtils"

interface GraphQLProviderProps {
  route?: string
  service?: ServiceType
  children: ReactNode
}

export const GraphQLProvider: React.FC<GraphQLProviderProps> = ({ route, service, children }) => {
  const { route: apiContextRoute, service: apiContextService } = useApiContext()
  const client = useGraphQLClient(route ?? apiContextRoute, service ?? apiContextService)

  return <UrqlProvider value={client}>{children}</UrqlProvider>
}
