import React, { createContext, useEffect, useState } from "react"

import { getNetworkCookie, setNetworkCookie } from "../utils/lambdaUtils"

export type NetworkType = "testnet" | "mainnet"

export const NetworkTable: Record<NetworkType, NetworkType> = {
  testnet: "testnet",
  mainnet: "mainnet",
}

interface INetworkContext {
  network: NetworkType
  setNetwork: React.Dispatch<React.SetStateAction<NetworkType>>
}

export const NetworkContext = createContext<INetworkContext>({
  network: NetworkTable.testnet,
  setNetwork: () => null,
})

type Props = {
  network?: NetworkType
  children: React.ReactNode
  featureFlag: string
  useNetworkValue: boolean
}

export function NetworkProvider({ children }: Props) {
  const [network, setNetwork] = useState(getNetworkCookie())
  useEffect(() => {
    const network = getNetworkCookie()

    // Set test if no network set
    setNetwork(network || NetworkTable.testnet)
    setNetworkCookie(network || NetworkTable.testnet)
  }, [])

  return (
    <NetworkContext.Provider value={{ network, setNetwork }}>{children}</NetworkContext.Provider>
  )
}
