import { AdminUser, App, Blockchain, Contract } from "@niftory/sdk/react"
import { ContractVersion, Role } from "@prisma/client"

import { NetworkType } from "../providers/NetworkProvider"

export function getUserRole(user: AdminUser): Role {
  if (!user) {
    return undefined
  }

  if (user.role != null) {
    return user.role as Role
  }

  if (!user.apps || user.apps.length === 0 || !user.role) {
    return undefined
  }

  return user.role as Role
}

export function getNFTPath(app: App): string {
  if (!app || !app.contract) {
    return undefined
  }

  if (app.contract?.version == ContractVersion.V1) {
    return app.contract.name
  } else {
    return app.id + "_" + app.contract.name
  }
}

export function getAppIdFromApp(app: App): string {
  if (!app) {
    return undefined
  }

  return app.id
}

export function getContract(app: App): Contract {
  if (!app) {
    return undefined
  }

  return app.contract
}

export function getCollectionPath(currentApp: App): string {
  return `${currentApp.id}_${currentApp?.contract?.name}`
}

export function getContractUrlAndSourcePath(network: NetworkType, app: App) {
  if (!app || !network) {
    return null
  }
  let baseUrl = null
  const contractAddress = app?.contract?.address
  const contractName = app?.contract?.name

  switch (app?.blockchain) {
    case Blockchain.Flow: {
      let flowScanUrl
      if (network === "mainnet") {
        flowScanUrl = process.env.NEXT_PUBLIC_FLOW_SCAN_URL
      } else {
        flowScanUrl = process.env.NEXT_PUBLIC_TESTNET_FLOW_SCAN_URL
      }
      baseUrl = `${flowScanUrl}/contract/A.${contractAddress?.replace("0x", "")}.${contractName}`
      const sourceCodePath = "/overview"
      return {
        baseUrl,
        sourceCodePath,
      }
    }
    case Blockchain.Polygon: {
      let polygonScanUrl
      if (network === "mainnet") {
        polygonScanUrl = process.env.NEXT_PUBLIC_POLYGON_SCAN_URL
      } else {
        polygonScanUrl = process.env.NEXT_PUBLIC_TESTNET_POLYGON_SCAN_URL
      }
      const baseUrl = `${polygonScanUrl}/address/${contractAddress}`
      const sourceCodePath = "#code"
      return {
        baseUrl,
        sourceCodePath,
      }
    }
    case Blockchain.Ethereum: {
      let ethereumScanUrl
      if (network === "mainnet") {
        ethereumScanUrl = process.env.NEXT_PUBLIC_ETHEREUM_SCAN_URL
      } else {
        ethereumScanUrl = process.env.NEXT_PUBLIC_TESTNET_ETHEREUM_SCAN_URL
      }
      const baseUrl = `${ethereumScanUrl}/address/${contractAddress}`
      const sourceCodePath = "#code"
      return {
        baseUrl,
        sourceCodePath,
      }
    }
    default:
      throw new Error(`No SourceCode and Scan URL mapping exists for blockchain=${app?.blockchain}`)
  }
}
