import { AdminUser, useAdminUserQuery } from "@niftory/sdk/react"

import { useAuthContext } from "./useAuthContext"

export function useAdminUserSession() {
  const { session, isLoading: sessionLoading, refreshSession: _refreshSession } = useAuthContext()
  const [{ data, fetching, error }, reExecuteQuery] = useAdminUserQuery({
    pause: sessionLoading || !session?.authToken,
  })

  const refreshSession = async () => {
    reExecuteQuery({ requestPolicy: "network-only" })
    await _refreshSession()
  }
  return {
    user: data?.adminUser as AdminUser,
    session,
    loading: fetching || sessionLoading,
    error,
    refreshSession,
  }
}
