import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import { addYears } from "date-fns"
import { JsonWebTokenError } from "jsonwebtoken"
import { Session } from "next-auth"
import { parseCookies, setCookie } from "nookies"
import urljoin from "url-join"

import { NetworkTable, NetworkType } from "../providers/NetworkProvider"

export type ServiceType = "api" | "blockchain" | "db" | "auth"

export const LambdaEndpoints: Record<NetworkType, Record<ServiceType, string>> = {
  testnet: {
    api: process.env.NEXT_PUBLIC_TESTNET_API_SERVICE,
    db: process.env.NEXT_PUBLIC_TESTNET_DB_SERVICE,
    auth: process.env.TESTNET_AUTH_SERVICE,
    blockchain: process.env.NEXT_PUBLIC_TESTNET_BLOCKCHAIN_SERVICE,
  },
  mainnet: {
    api: process.env.NEXT_PUBLIC_MAINNET_API_SERVICE,
    db: process.env.NEXT_PUBLIC_MAINNET_DB_SERVICE,
    auth: process.env.MAINNET_AUTH_SERVICE,
    blockchain: process.env.NEXT_PUBLIC_MAINNET_BLOCKCHAIN_SERVICE,
  },
}

export function getLambdaPath(route: string, service: ServiceType, currentNetwork?: NetworkType) {
  const network = currentNetwork || getNetworkCookie()

  const prefix = LambdaEndpoints[network][service]

  if (!prefix) {
    throw new Error(
      `Failed to find API route prefix for ${service} in network ${network}. Make sure you have NEXT_PUBLIC_DB_SERVICE,
      NEXT_PUBLIC_BLOCKCHAIN_SERVICE, NEXT_PUBLIC_API_SERVICE, and NEXT_PUBLIC_AUTH_SERVICE defined`
    )
  }

  return urljoin(prefix, route)
}

export function getApiKey(service: ServiceType) {
  if (service === "api") {
    // TODO: saqadri - use server-side props to get API key -- it's not good hygiene to have API key exposed to client
    // (though it isn't a security vulnerability)
    return process.env.NEXT_PUBLIC_API_KEY
  }

  return null
}

export async function tryPostLambda(
  session: Session,
  route: string,
  data: any,
  service: ServiceType = "db"
): Promise<AxiosResponse | undefined> {
  try {
    return await postLambda(session, route, data, service)
  } catch (e) {
    console.error(`Call to lamdba route '${route}' failed`, e)
    return e
  }
}

export async function postLambda(
  session: Session,
  route: string,
  data: any,
  service: ServiceType = "db",
  enforceJWT = true
): Promise<AxiosResponse> {
  const params: AxiosRequestConfig = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  }

  return callLambda(session, route, params, service, enforceJWT)
}

const encodeGetParams = (p: { [s: string]: unknown }) =>
  Object.entries(p)
    .map((kv) => kv.map(encodeURIComponent).join("="))
    .join("&")

export async function getLambda(
  session: Session,
  route: string,
  getParams?: string | { [s: string]: unknown },
  service: ServiceType = "db",
  enforceJWT = true
): Promise<AxiosResponse> {
  const params: AxiosRequestConfig = {
    method: "GET",
  }

  let routeWithParams = route
  if (getParams != null) {
    if (typeof getParams === "string") {
      routeWithParams = urljoin(route, encodeURIComponent(getParams))
    } else {
      routeWithParams = urljoin(route, `?${encodeGetParams(getParams)}`)
    }
  }

  return callLambda(session, routeWithParams, params, service, enforceJWT)
}

export async function callLambda(
  session: Session,
  route: string,
  params: AxiosRequestConfig,
  service: ServiceType = "db",
  enforceJWT = true
): Promise<AxiosResponse> {
  const network = getNetworkCookie()
  const token = session[`${network}:authToken`] ?? session?.authToken
  if (enforceJWT && !token) {
    throw new JsonWebTokenError("Session unauthenticated - JWT not found")
  }

  const fullRoute = getLambdaPath(route, service, network)
  params.url = fullRoute

  if (token) {
    params.headers = {
      ...params.headers,
      Authorization: `Bearer ${token}`,
    }
  }

  const apiKey = getApiKey(service)
  if (apiKey) {
    params.headers = {
      ...params.headers,
      "X-Niftory-API-Key": apiKey,
    }
  }

  const response = await axios(params)

  return response
}

export const getNetworkCookie = (): NetworkType => {
  let { network } = parseCookies()
  if (!network || !(network in NetworkTable)) {
    setNetworkCookie(NetworkTable.testnet)
    network = NetworkTable.testnet
  }
  return network as NetworkType
}

// Network cookie expiry in years
const NETWORK_COOKIE_EXPIRY_YEAR = 1
export const setNetworkCookie = (network: NetworkType) => {
  const expires = addYears(new Date(), NETWORK_COOKIE_EXPIRY_YEAR)
  setCookie(null, "network", network, { path: "/", expires })
}
