import { GrowthBookProvider } from "@growthbook/growthbook-react"
import React, { useEffect, useState } from "react"

import { getGrowthBook } from "../tools/growthbook"

type FeatureFlagProviderProps = {
  children: React.ReactNode // 👈️ type children
}

export const FeatureFlagProvider: React.FC<FeatureFlagProviderProps> = (
  props: FeatureFlagProviderProps
) => {
  const [provider, setProvider] = useState(null)

  useEffect(() => {
    getGrowthBook().then((gb) => setProvider(gb))
  }, [])

  return <GrowthBookProvider growthbook={provider}>{props.children}</GrowthBookProvider>
}
