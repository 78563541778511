import { useRouter } from "next/router"
import { Session } from "next-auth"
import { signIn as nextAuthSignIn, signOut as nextAuthSignOut } from "next-auth/react"
import { createContext, useCallback, useEffect, useState } from "react"
import { useNetworkSession } from "src/hooks/core/useNetworkSession"
import {
  removeCurrentNetworkAuthCookies,
  removeNetworkAuthCookies,
} from "src/lib/utils/cookie-utils"
import { getNetworkCookie } from "src/lib/utils/lambdaUtils"

type AuthComponentProps = {
  children: React.ReactNode
  requireAuth?: boolean
}

interface IAuthContext {
  session: Session & { error?: string }
  isLoading: boolean
  refreshSession: () => Promise<void>
  signIn: (callbackUrl?: string) => Promise<void>
  signOut: () => Promise<void>
}

export const AuthContext = createContext<IAuthContext>(null)

export function Auth({ requireAuth, children }: AuthComponentProps) {
  const router = useRouter()

  const { data: session, status, update } = useNetworkSession()
  const sessionLoading = status === "loading"

  const [isAuthenticating, setIsAuthenticating] = useState(false)
  const isLoading = sessionLoading || isAuthenticating

  const signIn = useCallback(
    async (callbackUrl?: string) => {
      setIsAuthenticating(true)

      const network = getNetworkCookie()

      removeCurrentNetworkAuthCookies(network)
      const provider = `niftory-${network}`
      await nextAuthSignIn(
        provider,
        { callbackUrl },
        { email: (router.query.email as string) || "" }
      )
    },
    [router.query.email]
  )

  const signOut = useCallback(async () => {
    setIsAuthenticating(true)

    // Route to root to prevent routing back to same page on login
    await router.push("/")
    await nextAuthSignOut({ redirect: false })
    removeNetworkAuthCookies()

    setIsAuthenticating(false)
  }, [router])

  useEffect(() => {
    if (!requireAuth || isLoading) {
      return
    }

    if (session?.error) {
      console.error(session.error)
      signOut()
      return
    }

    if (!session && router.isReady && router.pathname !== "login") {
      router.push({ pathname: "/login", query: { redirectURL: router.asPath, ...router.query } })
      return
    }
  }, [session, router, isLoading, signOut, requireAuth])

  const refreshSession = useCallback(async () => {
    setIsAuthenticating(true)
    await update()
    setIsAuthenticating(false)
  }, [update])

  return (
    <AuthContext.Provider value={{ session, isLoading, signIn, signOut, refreshSession }}>
      {children}
    </AuthContext.Provider>
  )
}
