import { devtoolsExchange } from "@urql/devtools"
import { retryExchange } from "@urql/exchange-retry"
import { signOut } from "next-auth/react"
import { parseCookies } from "nookies"
import posthog from "posthog-js"
import {
  cacheExchange,
  Client as UrqlClient,
  createClient,
  dedupExchange,
  fetchExchange,
} from "urql"

import { NetworkType } from "../providers/NetworkProvider"
import { removeNetworkAuthCookies } from "../utils/cookie-utils"
import { getLambdaPath, ServiceType } from "../utils/lambdaUtils"
import { GraphQLOutput, GraphQLQuery } from "./types"

export type AuthState = {
  authToken: string
  authTokenExpiration: number
}

export function getGraphQLClient(
  route: string,
  headers: HeadersInit,
  service: ServiceType,
  network: NetworkType
) {
  const url = getLambdaPath(route, service, network)
  return createClient({
    url: url,
    fetchOptions: {
      headers: headers,
    },
    exchanges: [
      devtoolsExchange,
      dedupExchange,
      cacheExchange,
      retryExchange({ maxNumberAttempts: 10 }),
      fetchExchange,
    ],
  })
}

export async function graphqlQueryAsync<
  Outputs extends Record<string, unknown>,
  Variables extends object = object
>(client: UrqlClient, query: GraphQLQuery<Outputs>, variables: Variables) {
  const result = await client.query<GraphQLOutput<Outputs>, Variables>(query, variables).toPromise()
  if (result.error) {
    if (result.error?.response?.status === 403) {
      posthog.capture("ERROR_FORBIDDEN_QUERY_RESPONSE", {
        cookies: parseCookies(),
        query,
        variables,
      })
      signOut()
      removeNetworkAuthCookies()
    }
    throw result.error
  }

  return result.data
}

export async function graphqlMutationAsync<
  Outputs extends Record<string, unknown>,
  Variables extends object = object
>(client: UrqlClient, query: GraphQLQuery<Outputs>, variables?: Variables) {
  const result = await client.mutation(query, variables).toPromise()

  if (result.error) {
    if (result.error?.response?.status === 403) {
      posthog.capture("ERROR_FORBIDDEN_MUTATION_RESPONSE", {
        cookies: parseCookies(),
        query,
        variables,
      })
      signOut()
      removeNetworkAuthCookies()
    }
    throw result.error
  }

  return result.data
}
