import { addMonths } from "date-fns"
import { NextApiRequest } from "next"
import { destroyCookie, setCookie } from "nookies"

import { NetworkTable, NetworkType } from "../providers/NetworkProvider"

// Cookie expiry in months
const SECURE_COOKIE_EXPIRY = 1

export const createSecureCookie = (res, key, value) => {
  const expires = addMonths(new Date(), SECURE_COOKIE_EXPIRY)
  setCookie({ res }, key, value as string, {
    path: "/",
    secure: true,
    expires,
  })
}

export const getNetworkCookieKeys = (network = "default") => ({
  authToken: `${network}:authToken`,
  refreshToken: `${network}:refreshToken`,
  expireAt: `${network}:authTokenExpiresAt`,
  userId: `${network}:userId`,
  expired: `${network}:expired`,
})

export const getRequestNetworkCookie = (req: NextApiRequest) => {
  return (req.cookies["network"] ?? NetworkTable.testnet) as NetworkType
}

export const removeNetworkAuthCookies = () => {
  //Get all cookie keys
  const cookieList = new Set([
    ...Object.keys(NetworkTable)
      .map((key) => Object.values(getNetworkCookieKeys(key)))
      .flat(),
  ])

  cookieList.forEach((cookie) => {
    destroyCookie(null, cookie, {
      path: "/",
    })
  })
}
export const removeCurrentNetworkAuthCookies = (network: string) => {
  //Get network cookie keys
  const cookieList = Object.values(getNetworkCookieKeys(network))

  cookieList.forEach((cookie) => {
    destroyCookie(null, cookie, {
      path: "/",
    })
  })
}
